import React from 'react'
import ReactFullpage from '@fullpage/react-fullpage'
import { Link, graphql } from 'gatsby'
import SEO from '../components/seo'
import Nav from '../components/nav'
import ExploreIcon from '../components/explore'

const Download = ({ data }) => {
  return (
    <>
      <SEO title="Downloads" />
      <Nav>
        <div className="col text-left">
          <Link className="target fill-dark" to="/explore" style={{height:"1.875rem"}}><ExploreIcon /></Link>
        </div>
      </Nav>
      <ReactFullpage
        // fullpage options
        licenseKey={'C1912978-A39F4CE4-9F6AC28B-DFB573DA'}
        sectionsColor={['#e8e7e3']}
        lazyLoading
        scrollOverflow

        render={() => (
          <ReactFullpage.Wrapper>
            <section className="section" key="section0" id="section0">
              <div className="container p-5">
                <h2 className="title">
                  Downloads
                </h2>
                <div className="row">
                  {data.allMarkdownRemark.edges
                    // .filter(data => {
                    //   return data.node.fields.slug.includes('download')
                    // })
                    .map(({ node }) => (
                      <div key={node.id} className="col-md-4">
                        {/* <h5 className="mb-0" dangerouslySetInnerHTML={{ __html: node.html }} /> */}
                        <h5 className="mb-0">
                          <a href={node.frontmatter.pdf.publicURL}>{node.frontmatter.title} &#10515;</a>
                        </h5>
                        <span className="small text-muted">
                          Updated : {node.frontmatter.date}
                        </span>
                        
                      </div>
                    ))
                  }
                </div>
              </div>
            </section>
          </ReactFullpage.Wrapper>
        )}
      />
    </>
  )
}

export default Download

export const query = graphql`
  query {
    allMarkdownRemark(
      sort: {fields: [frontmatter___date], order: DESC}, filter: {fields: {slug: {regex: "/download/"}}}
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            pdf {
              publicURL
            }
          }
          fields {
            slug
          }
          excerpt
          html
        }
      }
    }
  }
`